<template>
   <div id="box">
      <!-- 单独的量表评估报告 -->
      <h1>{{ reportName }}指标测验报告</h1>
      <h2>重庆市精神卫生中心</h2>
      <table v-if="this.update.cname">
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center"
                class="inputDeep">
               姓名：{{ this.update.cname }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               就诊卡号：{{ this.update.cid }}
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               科室：{{ this.update.section }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: center">
               指导医生：{{ this.update.doctor }}
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="10"
                style="text-align: center">
               报告时间：{{ this.update.dateTime.replace("T", "-") }}
            </td>
         </tr>
         <tr>
            <td rowspan="1"
                colspan="10"
                id="jieshao"
                valign="top">
               报告简介：<br />
               {{ brief }}
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               序号
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               项目
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               评分
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               参考诊断
            </td>
         </tr>
         <tr>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               1
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               {{ this.update.etName }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               {{ this.update.score }}
            </td>
            <td valign="center"
                rowspan="1"
                colspan="2"
                style="text-align: center">
               {{ this.update.solution }}
            </td>
         </tr>
      </table>

      <table id="table2">
         <div style="text-align: left"
              :v-model="update.review">
            <p>测验结论：{{ this.update.review }}</p>
         </div>
      </table>
      <table id="table3">
         <tr style="height: 40px">
            <td valign="left"
                rowspan="1"
                colspan="4"
                style="text-align: rigth; border: none">
               _______年_______月_______日填
            </td>
            <td valign="center"
                rowspan="1"
                colspan="4"
                style="text-align: right; border: none">
               签字：________<br />
            </td>
         </tr>
      </table>

      <p style="text-align: left;color:black">当前时间：{{ this.updateTime }}</p>
      <!-- <el-button
      type="primary"
      @click="$router.push(`/ReportUpdate/${update.cname}/${update.id}`)"
      >修改报告</el-button -->
      <!-- > -->
   </div>
</template>

<script>
import api from "../../http/api";
export default {
   name: "Dashboard",
   data () {
      return {
         update: {},
         cname: "",
         CID: "",
         updateTime: "",
         MEQbrief:
            "(morningnsee-eveningness questionnaire,MEQ)为目前国际通行的睡眠-觉醒昼夜节律自然趋向的分型工具,归属睡眠行为自我评估的范畴。自MEQ-19临床应用以来,人类对个体惯常昼夜节律变化对疾病发生、发展的规律有了深层次的了解,对于时间生物学治疗,提供了指导和帮助,MEQ的临床应用有助于个体化、优化临床疾病的诊治。",
         HAMDbrief:
            "汉密顿抑郁量表（Hamilton Depression Scale，HAMD）是由Hamilton编制，是临床上评定抑郁状态时应用得最为普遍的量表。汉密顿抑郁量表在临床上具有很好的应用信度，在评定者经严格训练后，汉密顿抑郁量表的总分能较好地反映疾病严重程度。汉密顿抑郁量表在临床上方便实用。HAMD评定方法简便，标准明确，便于掌握，可用于抑郁症、躁郁症、神经症等多种疾病的抑郁症状之评定，尤其适用于抑郁症。作一次评定大约需15～20分钟。",
         PHQbrief:
            "抑郁症筛查量表(PHQ-9)：PHQ-9基于DSM-IV诊断标准的9个条目，是一个简便、有效的抑郁障碍自评量表,在抑郁症诊断的辅助和症状严重程度评估方面,均具有良好的信度和效度.在基于评估的治疗策略中,PHQ-9可以作为制订治疗方案的参考,以及治疗过程中对疗效的评估工具。适合所有年龄层次的人群使用。",
         EPDSbrief:
            "爱丁堡产后抑郁量表（EPDS）：EPDS是由Cox编制的专门用于产后抑郁筛查的量表，后经国内专家重新修订，该量表具有良好的信度和效度，在国内外应用广泛。",
         GDSbrief:
            "老年抑郁量表(GDS)：GDS是由Brank等人创制的专门针对老年人的抑郁筛查量表，能更敏感地检查老年抑郁患者所特有的躯体症状。在老年抑郁评估中的研究现，信度与效度检验以及应用情况，并重点分析其应用，主要包括在患有慢性躯体疾病的老年人，老年认知障碍患者，家庭结构类型不同的老年人，居家养老与居家不出的老年人，参与宗教活动的老年人中的应用。GDS简便、高效，在评估老年抑郁方面具有很好的实用性",
         BAIbrief:
            "贝克焦虑量表(Beck Anxiety Inventory)由美国阿隆·贝克(Aaron T. Beck)等编制，是一个含有21个项目的自评量表。该量表主要评定受试者被多种焦虑症状烦扰的程度。适用于具有焦虑症状的成年人。能比较准确地反映主观感受到的焦虑程度。",
         ESSbrief:
            "爱泼沃斯嗜睡量表（Epworth Sleepiness Scale，ESS）是由澳大利亚墨尔本的Epworth医院设计的，又称Epworth日间多睡量表。临床应用结果表明，ESS是一种十分简便的患者自我评估白天嗜睡程度的问卷表。此表临床意义：嗜睡可以通过Epworth 嗜睡量表作出半客观的评定。",
         ADbrief:
            "AD生活质量问卷（QOL-AD）：QOL-AD由美国华盛顿大学Logsdon等编制，可以反应患者的行为能力、生理功能、心理状态和人际环境，具有良好的信度和效度。",
         RPQbrief:
            "脑震荡后综合征问卷(RPQ)：RPQ由King等人编制，用于评估脑震荡后的综合情况和严重程度。",
         CMAIbrief:
            "Cohen-Mansfield躁动行为问卷(CMAI)：CMAI由Cohen-Mansfield编制，用于评估患有认知障碍的老年人躁动行为的出现频率。",
         PSQIbrief:
            "匹兹堡睡眠质量指数(PSQI)：PSQI由Buysse博士等人于1989年编制的，该量表适用于睡眠障碍患者、精神障碍患者评价睡眠质量，同时也适用于一般人睡眠质量的评估",
      };
   },
   created () {
      this.getOneReport();
      // console.log("update", this.update);
   },
   updated () { },
   mounted () {
      this.addDate();
   },

   computed: {
      reportName () {
         return this.update.etName;
      },
      cid () {
         return this.$route.params.cid;
      },
      id () {
         return this.$route.params.id;
      },
      // 简洁筛选对应渲染
      brief () {
         if (this.update.etId === "E1") {
            return this.MEQbrief;
         }
         if (this.update.etId === "E2") {
            return this.HAMDbrief;
         }
         if (this.update.etId === "E3") {
            return this.PHQbrief;
         }
         if (this.update.etId === "E4") {
            return this.EPDSbrief;
         }
         if (this.update.etId === "E5") {
            return this.GDSbrief;
         }
         if (this.update.etId === "E7") {
            return this.BAIbrief;
         }
         if (this.update.etId === "E8") {
            return this.ESSbrief;
         }
         if (this.update.etId === "E9") {
            return this.ADbrief;
         }
         if (this.update.etId === "E10") {
            return this.RPQbrief;
         }
         if (this.update.etId === "E11") {
            return this.CMAIbrief;
         }
         if (this.update.etId === "E12") {
            return this.PSQIbrief;
         }
      },
      // 睡眠觉醒类型
      MEQ () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            var arr = data.split(",");
            let meq = arr.map(Number)[2];
            return meq;
         } else {
            return "/";
         }
      },
      // 睡眠综合评分
      Sleep () {
         if (this.update.etId !== "E6") {
            return "你还没有做该项评估";
         } else {
            return this.update.score;
         }
      },
      Sleep_text () {
         if (this.update.etId !== "E6") {
            return "你还没有做该项评估";
         } else {
            return this.update.solution;
         }
      },
      // 焦虑评估
      BAI () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            var arr = data.split(",");
            let bai = arr.map(Number)[3];
            return bai;
         } else {
            return "/";
         }
      },
      // 抑郁评估
      HAMD () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            var arr = data.split(",");
            let hamd = arr.map(Number)[4];
            return hamd;
         } else {
            return "/";
         }
      },
      // 睡眠质量
      Sleep_quality () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            var arr = data.split(",");
            let a = arr.map(Number)[0];
            return a;
         } else {
            return "/";
         }
      },
      // 睡眠障碍
      Sleep_disorders () {
         if (this.update.data !== "暂无") {
            let data = this.update.data;
            var arr = data.split(",");
            let a = arr.map(Number)[1];
            return a;
         } else {
            return "/";
         }
      },
      // 睡眠觉醒类型诊断参考
      MEQText () {
         if (this.MEQ < 23) {
            return "分数不正常，请重新评估";
         }
         if (this.MEQ >= 23 && this.MEQ <= 26) {
            return "绝对 “夜晚”型";
         }
         if (this.MEQ >= 27 && this.MEQ <= 30) {
            return "绝对 “夜晚”型";
         }
         if (this.MEQ >= 31 && this.MEQ <= 34) {
            return "中度 “夜晚”型";
         }
         if (this.MEQ >= 35 && this.MEQ <= 38) {
            return "中度 “夜晚”型";
         }
         if (this.MEQ >= 39 && this.MEQ <= 41) {
            return "中度 “夜晚”型";
         }
         if (this.MEQ >= 42 && this.MEQ <= 45) {
            return "中间型";
         }
         if (this.MEQ >= 46 && this.MEQ <= 49) {
            return "中间型";
         }
         if (this.MEQ >= 50 && this.MEQ <= 53) {
            return "中间型";
         }
         if (this.MEQ >= 54 && this.MEQ <= 57) {
            return "中间型";
         }
         if (this.MEQ >= 58 && this.MEQ <= 61) {
            return "中度 “清晨”型";
         }
         if (this.MEQ >= 62 && this.MEQ <= 65) {
            return "中度 “清晨”型";
         }
         if (this.MEQ >= 66 && this.MEQ <= 69) {
            return "中度 “清晨”型";
         }
         if (this.MEQ >= 70 && this.MEQ <= 72) {
            return "绝对 “清晨”型";
         }
         if (this.MEQ >= 72 && this.MEQ <= 86) {
            return "中度 “清晨”型";
         }
      },
      BAIText () {
         if (this.BAI < 15) {
            return "无焦虑";
         }
         if (this.BAI >= 15 && this.BAI <= 25) {
            return "轻度焦虑";
         }
         if (this.BAI > 26 && this.BAI <= 35) {
            return "中度焦虑";
         }
         if (this.BAI >= 36) {
            return "重度焦虑";
         }
      },
      HAMDText () {
         if (this.HAMD < 8) {
            return "正常";
         }
         if (this.HAMD >= 8 && this.HAMD <= 20) {
            return "轻度";
         }
         if (this.HAMD > 20 && this.HAMD <= 35) {
            return "中度";
         }
         if (this.HAMD > 35) {
            return "重度";
         }
      },
      Sleep_qualityText () {
         if (this.Sleep_quality == 0) {
            return "很好";
         }
         if (this.Sleep_quality == 1) {
            return "较好";
         }
         if (this.Sleep_quality == 2) {
            return "较差";
         }
         if (this.Sleep_quality == 3) {
            return "很差";
         }
      },
      Sleep_disordersText () {
         if (this.Sleep_disorders == 0) {
            return "无";
         }
         if (this.Sleep_disorders == 1) {
            return "轻度";
         }
         if (this.Sleep_disorders == 2) {
            return "中度";
         }
         if (this.Sleep_disorders == 3) {
            return "重度";
         }
      },
   },

   methods: {
      async getOneReport (cid, id) {
         this.dialogVisible2 = true;
         const data = await api.patient.lookRportOne(this.cid, this.id);
         if (data.status == 200) {
            this.update = data.data.data[0];
            // this.name = data.data.data[0].cname;
            // console.log("姓名", this.name);
            // console.log("获取某一个数据：", this.update);
         }
      },
      //获取当前年月日
      addDate () {
         const nowDate = new Date();
         const date = {
            year: nowDate.getFullYear(),
            month: nowDate.getMonth() + 1,
            date: nowDate.getDate(),
         };
         const newmonth = date.month > 10 ? date.month : "0" + date.month;
         const day = date.date > 10 ? date.date : "0" + date.date;
         this.updateTime = date.year + "-" + newmonth + "-" + day;
      },
   },
};
</script>

<style lang="scss" scoped>
#box {
   width: 800px;
   margin: auto;
}
h1 {
   color: black;
}
h2 {
   color: black;
}
table {
   border: 1px solid black;
   border-collapse: collapse;
   margin: 0 auto;
   width: 800px;
}
table td,
th {
   border: 1px black solid;
   background-color: white;
   height: 40px;
   width: 100px;
   color: black;
   // border-right: none;
}
#table2 {
   width: 798px;
   height: 200px;
   border-top: none;

   div {
      width: 798px;
      height: 200px;
      border-right: none;
      p {
         // padding: 0;
         margin: 0px;
         padding: 20px 20px;
         color: black;
      }
   }
}
#table3 {
   border-top: none;
   color: black;
}
#jieshao {
   // line-height: 0px;
   word-wrap: break-word;
   word-break: break-all;
   padding: 0;
   width: 800px;
   text-align: left;
   height: 200px;
   // border-right: none;
   color: black;
}

/* 利用穿透，设置input边框隐藏 */
// .inputDeep >>> .el-input__inner {
//   border: 0;
// }
</style>
